/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment CurrentUser on User {\n    id\n    username\n    firstName\n    lastName\n    email\n    enabled\n    roles {\n      userId\n      roleName\n    }\n    groups {\n      userId\n      groupId\n    }\n    createDate\n    modifyDate\n  }\n": types.CurrentUserFragmentDoc,
    "\n  query GetCurrentUser {\n    currentUser {\n      ...CurrentUser\n    }\n  }\n": types.GetCurrentUserDocument,
    "\n  fragment CustomerConfiguration on CustomerConfiguration {\n    id\n    customerName\n    configKey\n    configValue\n    createDate\n    modifyDate\n  }\n": types.CustomerConfigurationFragmentDoc,
    "\n  query GetCustomerConfigurations {\n    customerConfigurations {\n      ...CustomerConfiguration\n    }\n  }\n": types.GetCustomerConfigurationsDocument,
    "\n  mutation SaveCustomerConfigurations($inputs: [CustomerConfigurationInput!]!) {\n    saveCustomerConfigurations(inputs: $inputs) {\n      customerConfigurations {\n        ...CustomerConfiguration\n      }\n    }\n  }\n": types.SaveCustomerConfigurationsDocument,
    "\n  mutation DeleteCustomerConfigurations($ids: [ID!]!) {\n    deleteCustomerConfigurations(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n": types.DeleteCustomerConfigurationsDocument,
    "\n  query GetMetadataLock($customerName: String) {\n    metadataLock(customerName: $customerName)\n  }\n": types.GetMetadataLockDocument,
    "\n  mutation LockMetadata($customerName: String, $isoDateTime: String) {\n    lockMetadata(customerName: $customerName, isoDateTime: $isoDateTime) {\n      ...CustomerConfiguration\n    }\n  }\n": types.LockMetadataDocument,
    "\n  query GetCustomerFieldsCustomers {\n    customerFieldsCustomers\n  }\n": types.GetCustomerFieldsCustomersDocument,
    "\n  fragment CustomerField on CustomerField {\n    id\n    customerName\n    resourceName\n    standardName\n    displayName\n    displayNameFrench\n    displayNameSpanish\n    definition\n    deprecated\n    majorChange\n    cloneField\n    inputType\n    createBy\n    modifyBy\n    createDate\n    modifyDate\n  }\n": types.CustomerFieldFragmentDoc,
    "\n  query GetCustomerFields {\n    customerFields {\n      ...CustomerField\n    }\n  }\n": types.GetCustomerFieldsDocument,
    "\n  mutation SaveCustomerFields($inputs: [CustomerFieldInput!]!) {\n    saveCustomerFields(inputs: $inputs) {\n      customerFields {\n        ...CustomerField\n      }\n    }\n  }\n": types.SaveCustomerFieldsDocument,
    "\n  mutation DeleteCustomerFields($ids: [ID!]!) {\n    deleteCustomerFields(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n": types.DeleteCustomerFieldsDocument,
    "\n  mutation GenerateCustomerFields($customerName: String!, $targetCustomerName: String!, $resourceName: String) {\n    generateCustomerFields(customerName: $customerName, targetCustomerName: $targetCustomerName, resourceName: $resourceName)\n  }\n": types.GenerateCustomerFieldsDocument,
    "\n  fragment CustomerFieldMetadata on CustomerFieldMetadata {\n    customerName\n    namesByResource\n    names\n  }\n": types.CustomerFieldMetadataFragmentDoc,
    "\n  query GetCustomerFieldMetadata(\n    $customerName: String!\n  ) {\n    customerFieldMetadata(customerName: $customerName) {\n      ...CustomerFieldMetadata\n    }\n  }\n": types.GetCustomerFieldMetadataDocument,
    "\n  query GetCustomerLookupsCustomers {\n    customerLookupsCustomers\n  }\n": types.GetCustomerLookupsCustomersDocument,
    "\n  fragment CustomerLookup on CustomerLookup {\n    id\n    customerName\n    lookupField\n    lookupValue\n    lookupDisplayName\n    lookupDisplayNameFrench\n    lookupDisplayNameSpanish\n    ordinal\n    lookupStatus\n    lookupFieldConstraint1\n    lookupValue1\n    lookupFieldConstraint2\n    lookupValue2\n    createBy\n    modifyBy\n    createDate\n    modifyDate\n  }\n": types.CustomerLookupFragmentDoc,
    "\n  query GetCustomerLookups {\n    customerLookups {\n      ...CustomerLookup\n    }\n  }\n": types.GetCustomerLookupsDocument,
    "\n  mutation SaveCustomerLookups($inputs: [CustomerLookupInput!]!) {\n    saveCustomerLookups(inputs: $inputs) {\n      customerLookups {\n        ...CustomerLookup\n      }\n    }\n  }\n": types.SaveCustomerLookupsDocument,
    "\n  mutation DeleteCustomerLookups($ids: [ID!]!) {\n    deleteCustomerLookups(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n": types.DeleteCustomerLookupsDocument,
    "\n  mutation GenerateCustomerLookups($customerName: String!, $targetCustomerName: String!, $resourceName: String, $fieldName: String) {\n    generateCustomerLookups(customerName: $customerName, targetCustomerName: $targetCustomerName, resourceName: $resourceName, fieldName: $fieldName)\n  }\n": types.GenerateCustomerLookupsDocument,
    "\n  fragment CustomerLookupMetadata on CustomerLookupMetadata {\n    customerName\n    valuesByField\n    values\n    statuses\n    fieldNames\n  }\n": types.CustomerLookupMetadataFragmentDoc,
    "\n  query GetCustomerLookupMetadata(\n    $customerName: String!\n  ) {\n    customerLookupMetadata(customerName: $customerName) {\n      ...CustomerLookupMetadata\n    }\n  }\n": types.GetCustomerLookupMetadataDocument,
    "\n  fragment Customer on Customer {\n    id\n    customerName\n    description\n    resoId\n    createDate\n    modifyDate\n  }\n": types.CustomerFragmentDoc,
    "\n  query GetCustomers {\n    customers {\n      ...Customer\n    }\n  }\n": types.GetCustomersDocument,
    "\n  mutation SaveCustomers($inputs: [CustomerInput!]!) {\n    saveCustomers(inputs: $inputs) {\n      customers {\n        ...Customer\n      }\n    }\n  }\n": types.SaveCustomersDocument,
    "\n  mutation DeleteCustomers($ids: [ID!]!) {\n    deleteCustomers(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n": types.DeleteCustomersDocument,
    "\n  fragment Field on Field {\n    id\n    resourceName\n    standardName\n    displayName\n    displayNameFrench\n    displayNameSpanish\n    definition\n    simpleDataType\n    maxLength\n    suggestedPrecision\n    recordId\n    lookupStatus\n    lookup\n    propertyTypes\n    collection\n    edmDataType\n    resoDefined\n    physical\n    queryable\n    validationType\n    createBy\n    modifyBy\n    createDate\n    modifyDate\n  }\n": types.FieldFragmentDoc,
    "\n  query GetFields {\n    fields {\n      ...Field\n    }\n  }\n": types.GetFieldsDocument,
    "\n  mutation SaveFields($inputs: [FieldInput!]!) {\n    saveFields(inputs: $inputs) {\n      fields {\n        ...Field\n      }\n    }\n  }\n": types.SaveFieldsDocument,
    "\n  mutation DeleteFields($ids: [ID!]!) {\n    deleteFields(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n": types.DeleteFieldsDocument,
    "\n  mutation GenerateFields($customerName: String!, $resourceName: String) {\n    generateFields(customerName: $customerName, resourceName: $resourceName)\n  }\n": types.GenerateFieldsDocument,
    "\n  fragment FieldMetadata on FieldMetadata {\n    resourceNames\n    namesByResource\n    names\n    lookups\n    simpleDataTypes\n    edmDataTypes\n    validationTypes\n    lookupStatuses\n    collections\n    propertyTypes\n  }\n": types.FieldMetadataFragmentDoc,
    "\n  query GetFieldMetadata {\n    fieldMetadata {\n      ...FieldMetadata\n    }\n  }\n": types.GetFieldMetadataDocument,
    "\n  fragment Lookup on Lookup {\n    id\n    lookupField\n    lookupValue\n    lookupDisplayName\n    lookupDisplayNameFrench\n    lookupDisplayNameSpanish\n    definition\n    lookupDisplayNameSynonyms\n    references\n    elementStatus\n    lookupId\n    lookupFieldId\n    resoDefined\n    createBy\n    modifyBy\n    createDate\n    modifyDate\n  }\n": types.LookupFragmentDoc,
    "\n  query GetLookups {\n    lookups {\n      ...Lookup\n    }\n  }\n": types.GetLookupsDocument,
    "\n  mutation SaveLookups($inputs: [LookupInput!]!) {\n    saveLookups(inputs: $inputs) {\n      lookups {\n        ...Lookup\n      }\n    }\n  }\n": types.SaveLookupsDocument,
    "\n  mutation DeleteLookups($ids: [ID!]!) {\n    deleteLookups(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n": types.DeleteLookupsDocument,
    "\n  mutation GenerateLookups($customerName: String!, $resourceName: String, $fieldName: String) {\n    generateLookups(customerName: $customerName, resourceName: $resourceName, fieldName: $fieldName)\n  }\n": types.GenerateLookupsDocument,
    "\n  fragment LookupMetadata on LookupMetadata {\n    valuesByField\n    values\n    elementStatuses\n    references\n    fieldNames\n  }\n": types.LookupMetadataFragmentDoc,
    "\n  query GetLookupMetadata {\n    lookupMetadata {\n      ...LookupMetadata\n    }\n  }\n": types.GetLookupMetadataDocument,
    "\n  fragment MappingRule on MappingRule {\n    id\n    source\n    customerName\n    targetCustomerName\n    from\n    to\n    trigger\n    tValue\n    action\n    aValue\n    constraints\n    roles\n  }\n": types.MappingRuleFragmentDoc,
    "\n  query GetMappingRulesPage(\n    $customerName: String\n    $search: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n  ) {\n    mappingRulesPage(\n      customerName: $customerName\n      search: $search\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n    ) {\n      id\n      page {\n        ...MappingRule\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n": types.GetMappingRulesPageDocument,
    "\n  mutation GenerateMappingRules($customerName: String!) {\n    generateMappingRules(customerName: $customerName)\n  }\n": types.GenerateMappingRulesDocument,
    "\n  mutation ImportMappingRules($customerName: String!, $targetCustomerName: String!, $rules: [MappingRuleInput!]!) {\n    importMappingRules(customerName: $customerName, targetCustomerName: $targetCustomerName, rules: $rules)\n  }\n": types.ImportMappingRulesDocument,
    "\n  fragment MappingStatistic on MappingStatistic {\n    id\n    customerName\n    customerResource\n    customerField\n    customerElement\n    displayName\n    summaryStatistics\n    usagePercentage\n    lastUsedDate\n    statistics\n    similarValidation\n    bestMatches\n    targetCustomerBestMatches\n  }\n": types.MappingStatisticFragmentDoc,
    "\n  query GetMappingStatistic(\n    $customerName: String!\n    $customerResource: String!\n    $customerField: String!\n    $customerElement: String\n  ) {\n    mappingStatistic(\n      customerName: $customerName\n      customerResource: $customerResource\n      customerField: $customerField\n      customerElement: $customerElement\n    ) {\n      ...MappingStatistic\n    }\n  }\n": types.GetMappingStatisticDocument,
    "\n  query GetMappingStatistics {\n    mappingStatistics {\n      ...MappingStatistic\n    }\n  }\n": types.GetMappingStatisticsDocument,
    "\n  query GetPreviewCustomers {\n    previewCustomers\n  }\n": types.GetPreviewCustomersDocument,
    "\n  query GetTargetCustomers {\n    previewTargetCustomers\n  }\n": types.GetTargetCustomersDocument,
    "\n  fragment CustomerAndTargetCustomer on CustomerAndTargetCustomer {\n    customerName\n    targetCustomerName\n  }\n": types.CustomerAndTargetCustomerFragmentDoc,
    "\n  query GetMappingCustomers {\n    mappingCustomers {\n      ...CustomerAndTargetCustomer\n    }\n  }\n": types.GetMappingCustomersDocument,
    "\n  fragment Mapping on Mapping {\n    id\n    customerName\n    customerResource\n    customerField\n    customerDescription\n    customerElement\n    customerClassName\n    customerDataType\n    targetCustomerName\n    resoResource\n    resoField\n    resoElement\n    status\n    notes\n    logging\n    errorNode\n  }\n": types.MappingFragmentDoc,
    "\n  fragment MappingMetadata on MappingMetadata {\n    targetCustomers\n    customerResources\n    customerFields\n  }\n": types.MappingMetadataFragmentDoc,
    "\n  query GetMappingPage(\n    $customerName: String!\n    $search: String\n    $filter: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n    $filters: [FilterInput]\n  ) {\n    mappingPage(\n      customerName: $customerName\n      search: $search\n      filter: $filter\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n      filters: $filters\n    ) {\n      id\n      page {\n        ...Mapping\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n": types.GetMappingPageDocument,
    "\n  query GetMappingMetadata($customerName: String!) {\n    mappingMetadata(customerName: $customerName) {\n      ...MappingMetadata\n    }\n  }\n": types.GetMappingMetadataDocument,
    "\n  query GetSummary($customerName: String) {\n    summary(customerName: $customerName) {\n      seedRequirement {\n        id\n        resourceName\n        targetCustomerName\n        subjectName\n        requirementStatus\n        type\n        responseMessage\n        createDate\n        modifyDate\n      }\n      customerRequirement {\n        id\n        resourceName\n        targetCustomerName\n        subjectName\n        requirementStatus\n        type\n        responseMessage\n        createDate\n        modifyDate\n      }\n      customerName\n      resource\n      field\n      element\n    }\n  }\n": types.GetSummaryDocument,
    "\n  query GetMappings {\n    mappings {\n      ...Mapping\n    }\n  }\n": types.GetMappingsDocument,
    "\n  mutation SaveMappings($inputs: [MappingInput!]!) {\n    saveMappings(inputs: $inputs) {\n      mappings {\n        ...Mapping\n      }\n    }\n  }\n": types.SaveMappingsDocument,
    "\n  mutation DeleteMappings($ids: [ID!]!) {\n    deleteMappings(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n": types.DeleteMappingsDocument,
    "\n  mutation BulkChangeMappings(\n    $customerName: String!\n    $targetCustomerName: String\n    $columns: [Column!]!\n    $setColumns: [SetColumn!]!\n    $bulkDelete: Boolean\n    $dryRun: Boolean\n  ) {\n    bulkChangeMappings(\n      customerName: $customerName\n      targetCustomerName: $targetCustomerName\n      columns: $columns\n      setColumns: $setColumns\n      bulkDelete: $bulkDelete\n      dryRun: $dryRun\n    )\n  }\n": types.BulkChangeMappingsDocument,
    "\n  mutation AutofillResources($customerName: String!, $targetCustomerName: String!, $weights: Weights!) {\n    autofillResources(customerName: $customerName, targetCustomerName: $targetCustomerName, weights: $weights)\n  }\n": types.AutofillResourcesDocument,
    "\n  mutation AutofillFields(\n    $customerName: String!\n    $targetCustomerName: String!\n    $fieldWeights: Weights!\n    $elementWeights: Weights!\n    $resoResource: String\n  ) {\n    autofillFields(\n      customerName: $customerName\n      targetCustomerName: $targetCustomerName\n      fieldWeights: $fieldWeights\n      elementWeights: $elementWeights\n      resoResource: $resoResource\n    )\n  }\n": types.AutofillFieldsDocument,
    "\n  mutation AutofillElements(\n    $customerName: String!\n    $targetCustomerName: String!\n    $weights: Weights!\n    $resoResource: String\n    $resoField: String\n  ) {\n    autofillElements(\n      customerName: $customerName\n      targetCustomerName: $targetCustomerName\n      weights: $weights\n      resoResource: $resoResource\n      resoField: $resoField\n    )\n  }\n": types.AutofillElementsDocument,
    "\n  fragment MappingResource on Resource {\n    # id\n    name\n    # description\n    # createDate\n    # modifyDate\n  }\n": types.MappingResourceFragmentDoc,
    "\n  fragment MappingField on Field {\n    # id\n    resourceName\n    standardName\n    displayName\n    # displayNameFrench\n    # displayNameSpanish\n    definition\n    simpleDataType\n    # maxLength\n    # suggestedPrecision\n    # recordId\n    # lookupStatus\n    lookup\n    # propertyTypes\n    # collection\n    # edmDataType\n    resoDefined\n    # physical\n    # queryable\n    # validationType\n    # createBy\n    # modifyBy\n    # createDate\n    # modifyDate\n  }\n": types.MappingFieldFragmentDoc,
    "\n  fragment MappingLookup on Lookup {\n    # id\n    lookupField\n    lookupValue\n    lookupDisplayName\n    # lookupDisplayNameFrench\n    # lookupDisplayNameSpanish\n    definition\n    # lookupDisplayNameSynonyms\n    # references\n    # elementStatus\n    # lookupId\n    # lookupFieldId\n    resoDefined\n    # createBy\n    # modifyBy\n    # createDate\n    # modifyDate\n  }\n": types.MappingLookupFragmentDoc,
    "\n  fragment MappingCustomerField on CustomerField {\n    # id\n    customerName\n    resourceName\n    standardName\n    displayName\n    # displayNameFrench\n    # displayNameSpanish\n    definition\n    # deprecated\n    # majorChange\n    # cloneField\n    # inputType\n    # createBy\n    # modifyBy\n    # createDate\n    # modifyDate\n  }\n": types.MappingCustomerFieldFragmentDoc,
    "\n  fragment MappingCustomerLookup on CustomerLookup {\n    # id\n    customerName\n    lookupField\n    lookupValue\n    lookupDisplayName\n    # lookupDisplayNameFrench\n    # lookupDisplayNameSpanish\n    # ordinal\n    # lookupStatus\n    # lookupFieldConstraint1\n    # lookupValue1\n    # lookupFieldConstraint2\n    # lookupValue2\n    # createBy\n    # modifyBy\n    # createDate\n    # modifyDate\n  }\n": types.MappingCustomerLookupFragmentDoc,
    "\n  fragment AmpMetadata on AmpMetadata {\n    customers\n    resources {\n      ...MappingResource\n    }\n    fields {\n      ...MappingField\n    }\n    lookups {\n      ...MappingLookup\n    }\n    customerFields {\n      ...MappingCustomerField\n    }\n    customerLookups {\n      ...MappingCustomerLookup\n    }\n  }\n": types.AmpMetadataFragmentDoc,
    "\n  query GetAmpMetadata($customerName: String!) {\n    ampMetadata(customerName: $customerName) {\n      ...AmpMetadata\n    }\n  }\n": types.GetAmpMetadataDocument,
    "\n  fragment ReportStatistics on ReportStatistics {\n    count\n    unique\n    filled\n    names\n  }\n": types.ReportStatisticsFragmentDoc,
    "\n  fragment ReportInfo on ReportInfo {\n    total\n    unique\n    filled\n    unmapped {\n      ...ReportStatistics\n    }\n    verify {\n      ...ReportStatistics\n    }\n    review {\n      ...ReportStatistics\n    }\n    mapped {\n      ...ReportStatistics\n    }\n    deleted {\n      ...ReportStatistics\n    }\n  }\n": types.ReportInfoFragmentDoc,
    "\n  fragment MappingReport on MappingReport {\n    resourceInfo {\n      ...ReportInfo\n    }\n    fieldInfo {\n      ...ReportInfo\n    }\n  }\n": types.MappingReportFragmentDoc,
    "\n  query GetMappingReport($customerName: String!, $targetCustomerName: String!) {\n    mappingReport(customerName: $customerName, targetCustomerName: $targetCustomerName) {\n      ...MappingReport\n    }\n  }\n": types.GetMappingReportDocument,
    "\n  fragment Requirement on Requirement {\n    id\n    resourceName\n    targetCustomerName\n    subjectName\n    requirementStatus\n    type\n    responseMessage\n    createDate\n    modifyDate\n  }\n": types.RequirementFragmentDoc,
    "\n  query GetSeedRequirements($resourceName: String!) {\n    seedRequirements(resourceName: $resourceName) {\n      ...Requirement\n    }\n  }\n": types.GetSeedRequirementsDocument,
    "\n  query GetCustomerRequirements($resourceName: String!, $targetCustomerName: String!) {\n    customerRequirements(resourceName: $resourceName, targetCustomerName: $targetCustomerName) {\n      ...Requirement\n    }\n  }\n": types.GetCustomerRequirementsDocument,
    "\n  mutation SaveRequirements($inputs: [RequirementInput!]!) {\n    saveRequirements(inputs: $inputs) {\n      requirements {\n        ...Requirement\n      }\n    }\n  }\n": types.SaveRequirementsDocument,
    "\n  mutation DeleteRequirements($ids: [ID!]!) {\n    deleteRequirements(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n": types.DeleteRequirementsDocument,
    "\n  fragment Resource on Resource {\n    id\n    name\n    description\n    createDate\n    modifyDate\n  }\n": types.ResourceFragmentDoc,
    "\n  query GetResources {\n    resources {\n      ...Resource\n    }\n  }\n": types.GetResourcesDocument,
    "\n  mutation SaveResources($inputs: [ResourceInput!]!) {\n    saveResources(inputs: $inputs) {\n      resources {\n        ...Resource\n      }\n    }\n  }\n": types.SaveResourcesDocument,
    "\n  mutation DeleteResources($ids: [ID!]!) {\n    deleteResources(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n": types.DeleteResourcesDocument,
    "\n  fragment RetsData on RetsData {\n    id\n    sourceCustomerName\n    sourceResourceName\n    sourceClassName\n    sourceBoardName\n    sourceUniqueKey\n    sourceListingKey\n    sourceMlsStatus\n    destinationCustomerName\n    destinationListingKeyAssigned\n    destinationDuplicatesListingKey\n  }\n": types.RetsDataFragmentDoc,
    "\n  query GetRetsDataPage(\n    $customerName: String\n    $search: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n    $filters: [FilterInput]\n  ) {\n    retsDataPage(\n      customerName: $customerName\n      search: $search\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n      filters: $filters\n    ) {\n      id\n      page {\n        ...RetsData\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n": types.GetRetsDataPageDocument,
    "\n  query GetRetsDataCustomers {\n    retsDataCustomers\n  }\n": types.GetRetsDataCustomersDocument,
    "\n  fragment RetsField on RetsField {\n    id\n    customerName\n    resourceName\n    resourceVisibleName\n    resourceDescription\n    keyField\n    classVersion\n    classDate\n    lookupVersion\n    lookupDate\n    className\n    classVisibleName\n    classDescription\n    tableVersion\n    tableDate\n    metadataEntryId\n    systemName\n    standardName\n    longName\n    dbName\n    shortName\n    maximumLength\n    dataType\n    fieldPrecision\n    searchable\n    interpretation\n    lookupName\n    maxSelect\n    units\n    inIndex\n    minimum\n    maximum\n    required\n    uniqueIdentifier\n    inKeyIndex\n  }\n": types.RetsFieldFragmentDoc,
    "\n  query GetRetsFieldsPage(\n    $customerName: String\n    $resource: String\n    $field: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n  ) {\n    retsFieldsPage(\n      customerName: $customerName\n      resource: $resource\n      field: $field\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n    ) {\n      id\n      page {\n        ...RetsField\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n": types.GetRetsFieldsPageDocument,
    "\n  query GetRetsField(\n    $customerName: String!,\n    $resourceName: String!,\n    $className: String,\n    $systemName: String!\n  ) {\n    retsField(\n      customerName: $customerName\n      resourceName: $resourceName\n      className: $className\n      systemName: $systemName\n    ) {\n      ...RetsField\n    }\n  }\n": types.GetRetsFieldDocument,
    "\n  query GetRetsFieldsCustomers {\n    retsFieldsCustomers\n  }\n": types.GetRetsFieldsCustomersDocument,
    "\n  fragment RetsLookup on RetsLookup {\n    id\n    customerName\n    metadataResourceName\n    metadataVersion\n    metadataDate\n    lookupMetadataEntryId\n    lookupName\n    visibleName\n    lookupTypeVersion\n    lookupTypeDate\n    metadataEntryId\n    longValue\n    shortValue\n    value\n  }\n": types.RetsLookupFragmentDoc,
    "\n  query GetRetsLookupsPage(\n    $customerName: String\n    $resource: String\n    $field: String\n    $value: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n  ) {\n    retsLookupsPage(\n      customerName: $customerName\n      resource: $resource\n      field: $field\n      value: $value\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n    ) {\n      id\n      page {\n        ...RetsLookup\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n": types.GetRetsLookupsPageDocument,
    "\n  query GetRetsFieldLookups(\n    $customerName: String\n    $resourceName: String!\n    $lookupName: String!\n  ) {\n    retsFieldLookups(\n      customerName: $customerName\n      resourceName: $resourceName\n      lookupName: $lookupName\n    ) {\n      ...RetsLookup\n    }\n  }\n": types.GetRetsFieldLookupsDocument,
    "\n  query GetRetsLookupsCustomers {\n    retsLookupsCustomers\n  }\n": types.GetRetsLookupsCustomersDocument,
    "\n  mutation ClearServerCache {\n    clearServerCache\n  }\n": types.ClearServerCacheDocument,
    "\n  mutation WipeCustomerMetadata($customerName: String!) {\n    wipeCustomerMetadata(customerName: $customerName)\n  }\n": types.WipeCustomerMetadataDocument,
    "\n  mutation DeduplicateLookups {\n    deduplicateLookups\n  }\n": types.DeduplicateLookupsDocument,
    "\n  fragment User on User {\n    id\n    fullName\n    email\n    roles {\n      userId\n      roleName\n    }\n    groups {\n      userId\n      groupId\n    }\n    createDate\n    modifyDate\n  }\n": types.UserFragmentDoc,
    "\n  query GetUser($id: ID!) {\n    user(id: $id) {\n      ...User\n    }\n  }\n": types.GetUserDocument,
    "\n  query GetUsers {\n    users {\n      ...User\n    }\n  }\n": types.GetUsersDocument,
    "\n  mutation SaveUser($input: UserInput!) {\n    saveUser(input: $input) {\n      user {\n        ...User\n      }\n    }\n  }\n": types.SaveUserDocument,
    "\n  mutation DeleteUser($id: ID!) {\n    deleteUser(id: $id) {\n      id\n      deleted\n    }\n  }\n": types.DeleteUserDocument,
    "\n  mutation SaveGroups($id: ID!, $groups: [String!]) {\n    saveGroups(id: $id, groups: $groups) {\n      user {\n        ...User\n      }\n    }\n  }\n": types.SaveGroupsDocument,
    "\n  mutation SaveRoles($id: ID!, $roles: [String!]) {\n    saveRoles(id: $id, roles: $roles) {\n      user {\n        ...User\n      }\n    }\n  }\n": types.SaveRolesDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CurrentUser on User {\n    id\n    username\n    firstName\n    lastName\n    email\n    enabled\n    roles {\n      userId\n      roleName\n    }\n    groups {\n      userId\n      groupId\n    }\n    createDate\n    modifyDate\n  }\n"): (typeof documents)["\n  fragment CurrentUser on User {\n    id\n    username\n    firstName\n    lastName\n    email\n    enabled\n    roles {\n      userId\n      roleName\n    }\n    groups {\n      userId\n      groupId\n    }\n    createDate\n    modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCurrentUser {\n    currentUser {\n      ...CurrentUser\n    }\n  }\n"): (typeof documents)["\n  query GetCurrentUser {\n    currentUser {\n      ...CurrentUser\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CustomerConfiguration on CustomerConfiguration {\n    id\n    customerName\n    configKey\n    configValue\n    createDate\n    modifyDate\n  }\n"): (typeof documents)["\n  fragment CustomerConfiguration on CustomerConfiguration {\n    id\n    customerName\n    configKey\n    configValue\n    createDate\n    modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomerConfigurations {\n    customerConfigurations {\n      ...CustomerConfiguration\n    }\n  }\n"): (typeof documents)["\n  query GetCustomerConfigurations {\n    customerConfigurations {\n      ...CustomerConfiguration\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveCustomerConfigurations($inputs: [CustomerConfigurationInput!]!) {\n    saveCustomerConfigurations(inputs: $inputs) {\n      customerConfigurations {\n        ...CustomerConfiguration\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveCustomerConfigurations($inputs: [CustomerConfigurationInput!]!) {\n    saveCustomerConfigurations(inputs: $inputs) {\n      customerConfigurations {\n        ...CustomerConfiguration\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteCustomerConfigurations($ids: [ID!]!) {\n    deleteCustomerConfigurations(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCustomerConfigurations($ids: [ID!]!) {\n    deleteCustomerConfigurations(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMetadataLock($customerName: String) {\n    metadataLock(customerName: $customerName)\n  }\n"): (typeof documents)["\n  query GetMetadataLock($customerName: String) {\n    metadataLock(customerName: $customerName)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation LockMetadata($customerName: String, $isoDateTime: String) {\n    lockMetadata(customerName: $customerName, isoDateTime: $isoDateTime) {\n      ...CustomerConfiguration\n    }\n  }\n"): (typeof documents)["\n  mutation LockMetadata($customerName: String, $isoDateTime: String) {\n    lockMetadata(customerName: $customerName, isoDateTime: $isoDateTime) {\n      ...CustomerConfiguration\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomerFieldsCustomers {\n    customerFieldsCustomers\n  }\n"): (typeof documents)["\n  query GetCustomerFieldsCustomers {\n    customerFieldsCustomers\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CustomerField on CustomerField {\n    id\n    customerName\n    resourceName\n    standardName\n    displayName\n    displayNameFrench\n    displayNameSpanish\n    definition\n    deprecated\n    majorChange\n    cloneField\n    inputType\n    createBy\n    modifyBy\n    createDate\n    modifyDate\n  }\n"): (typeof documents)["\n  fragment CustomerField on CustomerField {\n    id\n    customerName\n    resourceName\n    standardName\n    displayName\n    displayNameFrench\n    displayNameSpanish\n    definition\n    deprecated\n    majorChange\n    cloneField\n    inputType\n    createBy\n    modifyBy\n    createDate\n    modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomerFields {\n    customerFields {\n      ...CustomerField\n    }\n  }\n"): (typeof documents)["\n  query GetCustomerFields {\n    customerFields {\n      ...CustomerField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveCustomerFields($inputs: [CustomerFieldInput!]!) {\n    saveCustomerFields(inputs: $inputs) {\n      customerFields {\n        ...CustomerField\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveCustomerFields($inputs: [CustomerFieldInput!]!) {\n    saveCustomerFields(inputs: $inputs) {\n      customerFields {\n        ...CustomerField\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteCustomerFields($ids: [ID!]!) {\n    deleteCustomerFields(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCustomerFields($ids: [ID!]!) {\n    deleteCustomerFields(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateCustomerFields($customerName: String!, $targetCustomerName: String!, $resourceName: String) {\n    generateCustomerFields(customerName: $customerName, targetCustomerName: $targetCustomerName, resourceName: $resourceName)\n  }\n"): (typeof documents)["\n  mutation GenerateCustomerFields($customerName: String!, $targetCustomerName: String!, $resourceName: String) {\n    generateCustomerFields(customerName: $customerName, targetCustomerName: $targetCustomerName, resourceName: $resourceName)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CustomerFieldMetadata on CustomerFieldMetadata {\n    customerName\n    namesByResource\n    names\n  }\n"): (typeof documents)["\n  fragment CustomerFieldMetadata on CustomerFieldMetadata {\n    customerName\n    namesByResource\n    names\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomerFieldMetadata(\n    $customerName: String!\n  ) {\n    customerFieldMetadata(customerName: $customerName) {\n      ...CustomerFieldMetadata\n    }\n  }\n"): (typeof documents)["\n  query GetCustomerFieldMetadata(\n    $customerName: String!\n  ) {\n    customerFieldMetadata(customerName: $customerName) {\n      ...CustomerFieldMetadata\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomerLookupsCustomers {\n    customerLookupsCustomers\n  }\n"): (typeof documents)["\n  query GetCustomerLookupsCustomers {\n    customerLookupsCustomers\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CustomerLookup on CustomerLookup {\n    id\n    customerName\n    lookupField\n    lookupValue\n    lookupDisplayName\n    lookupDisplayNameFrench\n    lookupDisplayNameSpanish\n    ordinal\n    lookupStatus\n    lookupFieldConstraint1\n    lookupValue1\n    lookupFieldConstraint2\n    lookupValue2\n    createBy\n    modifyBy\n    createDate\n    modifyDate\n  }\n"): (typeof documents)["\n  fragment CustomerLookup on CustomerLookup {\n    id\n    customerName\n    lookupField\n    lookupValue\n    lookupDisplayName\n    lookupDisplayNameFrench\n    lookupDisplayNameSpanish\n    ordinal\n    lookupStatus\n    lookupFieldConstraint1\n    lookupValue1\n    lookupFieldConstraint2\n    lookupValue2\n    createBy\n    modifyBy\n    createDate\n    modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomerLookups {\n    customerLookups {\n      ...CustomerLookup\n    }\n  }\n"): (typeof documents)["\n  query GetCustomerLookups {\n    customerLookups {\n      ...CustomerLookup\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveCustomerLookups($inputs: [CustomerLookupInput!]!) {\n    saveCustomerLookups(inputs: $inputs) {\n      customerLookups {\n        ...CustomerLookup\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveCustomerLookups($inputs: [CustomerLookupInput!]!) {\n    saveCustomerLookups(inputs: $inputs) {\n      customerLookups {\n        ...CustomerLookup\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteCustomerLookups($ids: [ID!]!) {\n    deleteCustomerLookups(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCustomerLookups($ids: [ID!]!) {\n    deleteCustomerLookups(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateCustomerLookups($customerName: String!, $targetCustomerName: String!, $resourceName: String, $fieldName: String) {\n    generateCustomerLookups(customerName: $customerName, targetCustomerName: $targetCustomerName, resourceName: $resourceName, fieldName: $fieldName)\n  }\n"): (typeof documents)["\n  mutation GenerateCustomerLookups($customerName: String!, $targetCustomerName: String!, $resourceName: String, $fieldName: String) {\n    generateCustomerLookups(customerName: $customerName, targetCustomerName: $targetCustomerName, resourceName: $resourceName, fieldName: $fieldName)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CustomerLookupMetadata on CustomerLookupMetadata {\n    customerName\n    valuesByField\n    values\n    statuses\n    fieldNames\n  }\n"): (typeof documents)["\n  fragment CustomerLookupMetadata on CustomerLookupMetadata {\n    customerName\n    valuesByField\n    values\n    statuses\n    fieldNames\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomerLookupMetadata(\n    $customerName: String!\n  ) {\n    customerLookupMetadata(customerName: $customerName) {\n      ...CustomerLookupMetadata\n    }\n  }\n"): (typeof documents)["\n  query GetCustomerLookupMetadata(\n    $customerName: String!\n  ) {\n    customerLookupMetadata(customerName: $customerName) {\n      ...CustomerLookupMetadata\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Customer on Customer {\n    id\n    customerName\n    description\n    resoId\n    createDate\n    modifyDate\n  }\n"): (typeof documents)["\n  fragment Customer on Customer {\n    id\n    customerName\n    description\n    resoId\n    createDate\n    modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomers {\n    customers {\n      ...Customer\n    }\n  }\n"): (typeof documents)["\n  query GetCustomers {\n    customers {\n      ...Customer\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveCustomers($inputs: [CustomerInput!]!) {\n    saveCustomers(inputs: $inputs) {\n      customers {\n        ...Customer\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveCustomers($inputs: [CustomerInput!]!) {\n    saveCustomers(inputs: $inputs) {\n      customers {\n        ...Customer\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteCustomers($ids: [ID!]!) {\n    deleteCustomers(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCustomers($ids: [ID!]!) {\n    deleteCustomers(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Field on Field {\n    id\n    resourceName\n    standardName\n    displayName\n    displayNameFrench\n    displayNameSpanish\n    definition\n    simpleDataType\n    maxLength\n    suggestedPrecision\n    recordId\n    lookupStatus\n    lookup\n    propertyTypes\n    collection\n    edmDataType\n    resoDefined\n    physical\n    queryable\n    validationType\n    createBy\n    modifyBy\n    createDate\n    modifyDate\n  }\n"): (typeof documents)["\n  fragment Field on Field {\n    id\n    resourceName\n    standardName\n    displayName\n    displayNameFrench\n    displayNameSpanish\n    definition\n    simpleDataType\n    maxLength\n    suggestedPrecision\n    recordId\n    lookupStatus\n    lookup\n    propertyTypes\n    collection\n    edmDataType\n    resoDefined\n    physical\n    queryable\n    validationType\n    createBy\n    modifyBy\n    createDate\n    modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFields {\n    fields {\n      ...Field\n    }\n  }\n"): (typeof documents)["\n  query GetFields {\n    fields {\n      ...Field\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveFields($inputs: [FieldInput!]!) {\n    saveFields(inputs: $inputs) {\n      fields {\n        ...Field\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveFields($inputs: [FieldInput!]!) {\n    saveFields(inputs: $inputs) {\n      fields {\n        ...Field\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteFields($ids: [ID!]!) {\n    deleteFields(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteFields($ids: [ID!]!) {\n    deleteFields(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateFields($customerName: String!, $resourceName: String) {\n    generateFields(customerName: $customerName, resourceName: $resourceName)\n  }\n"): (typeof documents)["\n  mutation GenerateFields($customerName: String!, $resourceName: String) {\n    generateFields(customerName: $customerName, resourceName: $resourceName)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FieldMetadata on FieldMetadata {\n    resourceNames\n    namesByResource\n    names\n    lookups\n    simpleDataTypes\n    edmDataTypes\n    validationTypes\n    lookupStatuses\n    collections\n    propertyTypes\n  }\n"): (typeof documents)["\n  fragment FieldMetadata on FieldMetadata {\n    resourceNames\n    namesByResource\n    names\n    lookups\n    simpleDataTypes\n    edmDataTypes\n    validationTypes\n    lookupStatuses\n    collections\n    propertyTypes\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFieldMetadata {\n    fieldMetadata {\n      ...FieldMetadata\n    }\n  }\n"): (typeof documents)["\n  query GetFieldMetadata {\n    fieldMetadata {\n      ...FieldMetadata\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Lookup on Lookup {\n    id\n    lookupField\n    lookupValue\n    lookupDisplayName\n    lookupDisplayNameFrench\n    lookupDisplayNameSpanish\n    definition\n    lookupDisplayNameSynonyms\n    references\n    elementStatus\n    lookupId\n    lookupFieldId\n    resoDefined\n    createBy\n    modifyBy\n    createDate\n    modifyDate\n  }\n"): (typeof documents)["\n  fragment Lookup on Lookup {\n    id\n    lookupField\n    lookupValue\n    lookupDisplayName\n    lookupDisplayNameFrench\n    lookupDisplayNameSpanish\n    definition\n    lookupDisplayNameSynonyms\n    references\n    elementStatus\n    lookupId\n    lookupFieldId\n    resoDefined\n    createBy\n    modifyBy\n    createDate\n    modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetLookups {\n    lookups {\n      ...Lookup\n    }\n  }\n"): (typeof documents)["\n  query GetLookups {\n    lookups {\n      ...Lookup\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveLookups($inputs: [LookupInput!]!) {\n    saveLookups(inputs: $inputs) {\n      lookups {\n        ...Lookup\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveLookups($inputs: [LookupInput!]!) {\n    saveLookups(inputs: $inputs) {\n      lookups {\n        ...Lookup\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteLookups($ids: [ID!]!) {\n    deleteLookups(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteLookups($ids: [ID!]!) {\n    deleteLookups(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateLookups($customerName: String!, $resourceName: String, $fieldName: String) {\n    generateLookups(customerName: $customerName, resourceName: $resourceName, fieldName: $fieldName)\n  }\n"): (typeof documents)["\n  mutation GenerateLookups($customerName: String!, $resourceName: String, $fieldName: String) {\n    generateLookups(customerName: $customerName, resourceName: $resourceName, fieldName: $fieldName)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment LookupMetadata on LookupMetadata {\n    valuesByField\n    values\n    elementStatuses\n    references\n    fieldNames\n  }\n"): (typeof documents)["\n  fragment LookupMetadata on LookupMetadata {\n    valuesByField\n    values\n    elementStatuses\n    references\n    fieldNames\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetLookupMetadata {\n    lookupMetadata {\n      ...LookupMetadata\n    }\n  }\n"): (typeof documents)["\n  query GetLookupMetadata {\n    lookupMetadata {\n      ...LookupMetadata\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MappingRule on MappingRule {\n    id\n    source\n    customerName\n    targetCustomerName\n    from\n    to\n    trigger\n    tValue\n    action\n    aValue\n    constraints\n    roles\n  }\n"): (typeof documents)["\n  fragment MappingRule on MappingRule {\n    id\n    source\n    customerName\n    targetCustomerName\n    from\n    to\n    trigger\n    tValue\n    action\n    aValue\n    constraints\n    roles\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMappingRulesPage(\n    $customerName: String\n    $search: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n  ) {\n    mappingRulesPage(\n      customerName: $customerName\n      search: $search\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n    ) {\n      id\n      page {\n        ...MappingRule\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n"): (typeof documents)["\n  query GetMappingRulesPage(\n    $customerName: String\n    $search: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n  ) {\n    mappingRulesPage(\n      customerName: $customerName\n      search: $search\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n    ) {\n      id\n      page {\n        ...MappingRule\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateMappingRules($customerName: String!) {\n    generateMappingRules(customerName: $customerName)\n  }\n"): (typeof documents)["\n  mutation GenerateMappingRules($customerName: String!) {\n    generateMappingRules(customerName: $customerName)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ImportMappingRules($customerName: String!, $targetCustomerName: String!, $rules: [MappingRuleInput!]!) {\n    importMappingRules(customerName: $customerName, targetCustomerName: $targetCustomerName, rules: $rules)\n  }\n"): (typeof documents)["\n  mutation ImportMappingRules($customerName: String!, $targetCustomerName: String!, $rules: [MappingRuleInput!]!) {\n    importMappingRules(customerName: $customerName, targetCustomerName: $targetCustomerName, rules: $rules)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MappingStatistic on MappingStatistic {\n    id\n    customerName\n    customerResource\n    customerField\n    customerElement\n    displayName\n    summaryStatistics\n    usagePercentage\n    lastUsedDate\n    statistics\n    similarValidation\n    bestMatches\n    targetCustomerBestMatches\n  }\n"): (typeof documents)["\n  fragment MappingStatistic on MappingStatistic {\n    id\n    customerName\n    customerResource\n    customerField\n    customerElement\n    displayName\n    summaryStatistics\n    usagePercentage\n    lastUsedDate\n    statistics\n    similarValidation\n    bestMatches\n    targetCustomerBestMatches\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMappingStatistic(\n    $customerName: String!\n    $customerResource: String!\n    $customerField: String!\n    $customerElement: String\n  ) {\n    mappingStatistic(\n      customerName: $customerName\n      customerResource: $customerResource\n      customerField: $customerField\n      customerElement: $customerElement\n    ) {\n      ...MappingStatistic\n    }\n  }\n"): (typeof documents)["\n  query GetMappingStatistic(\n    $customerName: String!\n    $customerResource: String!\n    $customerField: String!\n    $customerElement: String\n  ) {\n    mappingStatistic(\n      customerName: $customerName\n      customerResource: $customerResource\n      customerField: $customerField\n      customerElement: $customerElement\n    ) {\n      ...MappingStatistic\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMappingStatistics {\n    mappingStatistics {\n      ...MappingStatistic\n    }\n  }\n"): (typeof documents)["\n  query GetMappingStatistics {\n    mappingStatistics {\n      ...MappingStatistic\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPreviewCustomers {\n    previewCustomers\n  }\n"): (typeof documents)["\n  query GetPreviewCustomers {\n    previewCustomers\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTargetCustomers {\n    previewTargetCustomers\n  }\n"): (typeof documents)["\n  query GetTargetCustomers {\n    previewTargetCustomers\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CustomerAndTargetCustomer on CustomerAndTargetCustomer {\n    customerName\n    targetCustomerName\n  }\n"): (typeof documents)["\n  fragment CustomerAndTargetCustomer on CustomerAndTargetCustomer {\n    customerName\n    targetCustomerName\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMappingCustomers {\n    mappingCustomers {\n      ...CustomerAndTargetCustomer\n    }\n  }\n"): (typeof documents)["\n  query GetMappingCustomers {\n    mappingCustomers {\n      ...CustomerAndTargetCustomer\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Mapping on Mapping {\n    id\n    customerName\n    customerResource\n    customerField\n    customerDescription\n    customerElement\n    customerClassName\n    customerDataType\n    targetCustomerName\n    resoResource\n    resoField\n    resoElement\n    status\n    notes\n    logging\n    errorNode\n  }\n"): (typeof documents)["\n  fragment Mapping on Mapping {\n    id\n    customerName\n    customerResource\n    customerField\n    customerDescription\n    customerElement\n    customerClassName\n    customerDataType\n    targetCustomerName\n    resoResource\n    resoField\n    resoElement\n    status\n    notes\n    logging\n    errorNode\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MappingMetadata on MappingMetadata {\n    targetCustomers\n    customerResources\n    customerFields\n  }\n"): (typeof documents)["\n  fragment MappingMetadata on MappingMetadata {\n    targetCustomers\n    customerResources\n    customerFields\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMappingPage(\n    $customerName: String!\n    $search: String\n    $filter: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n    $filters: [FilterInput]\n  ) {\n    mappingPage(\n      customerName: $customerName\n      search: $search\n      filter: $filter\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n      filters: $filters\n    ) {\n      id\n      page {\n        ...Mapping\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n"): (typeof documents)["\n  query GetMappingPage(\n    $customerName: String!\n    $search: String\n    $filter: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n    $filters: [FilterInput]\n  ) {\n    mappingPage(\n      customerName: $customerName\n      search: $search\n      filter: $filter\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n      filters: $filters\n    ) {\n      id\n      page {\n        ...Mapping\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMappingMetadata($customerName: String!) {\n    mappingMetadata(customerName: $customerName) {\n      ...MappingMetadata\n    }\n  }\n"): (typeof documents)["\n  query GetMappingMetadata($customerName: String!) {\n    mappingMetadata(customerName: $customerName) {\n      ...MappingMetadata\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSummary($customerName: String) {\n    summary(customerName: $customerName) {\n      seedRequirement {\n        id\n        resourceName\n        targetCustomerName\n        subjectName\n        requirementStatus\n        type\n        responseMessage\n        createDate\n        modifyDate\n      }\n      customerRequirement {\n        id\n        resourceName\n        targetCustomerName\n        subjectName\n        requirementStatus\n        type\n        responseMessage\n        createDate\n        modifyDate\n      }\n      customerName\n      resource\n      field\n      element\n    }\n  }\n"): (typeof documents)["\n  query GetSummary($customerName: String) {\n    summary(customerName: $customerName) {\n      seedRequirement {\n        id\n        resourceName\n        targetCustomerName\n        subjectName\n        requirementStatus\n        type\n        responseMessage\n        createDate\n        modifyDate\n      }\n      customerRequirement {\n        id\n        resourceName\n        targetCustomerName\n        subjectName\n        requirementStatus\n        type\n        responseMessage\n        createDate\n        modifyDate\n      }\n      customerName\n      resource\n      field\n      element\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMappings {\n    mappings {\n      ...Mapping\n    }\n  }\n"): (typeof documents)["\n  query GetMappings {\n    mappings {\n      ...Mapping\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveMappings($inputs: [MappingInput!]!) {\n    saveMappings(inputs: $inputs) {\n      mappings {\n        ...Mapping\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveMappings($inputs: [MappingInput!]!) {\n    saveMappings(inputs: $inputs) {\n      mappings {\n        ...Mapping\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteMappings($ids: [ID!]!) {\n    deleteMappings(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMappings($ids: [ID!]!) {\n    deleteMappings(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation BulkChangeMappings(\n    $customerName: String!\n    $targetCustomerName: String\n    $columns: [Column!]!\n    $setColumns: [SetColumn!]!\n    $bulkDelete: Boolean\n    $dryRun: Boolean\n  ) {\n    bulkChangeMappings(\n      customerName: $customerName\n      targetCustomerName: $targetCustomerName\n      columns: $columns\n      setColumns: $setColumns\n      bulkDelete: $bulkDelete\n      dryRun: $dryRun\n    )\n  }\n"): (typeof documents)["\n  mutation BulkChangeMappings(\n    $customerName: String!\n    $targetCustomerName: String\n    $columns: [Column!]!\n    $setColumns: [SetColumn!]!\n    $bulkDelete: Boolean\n    $dryRun: Boolean\n  ) {\n    bulkChangeMappings(\n      customerName: $customerName\n      targetCustomerName: $targetCustomerName\n      columns: $columns\n      setColumns: $setColumns\n      bulkDelete: $bulkDelete\n      dryRun: $dryRun\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AutofillResources($customerName: String!, $targetCustomerName: String!, $weights: Weights!) {\n    autofillResources(customerName: $customerName, targetCustomerName: $targetCustomerName, weights: $weights)\n  }\n"): (typeof documents)["\n  mutation AutofillResources($customerName: String!, $targetCustomerName: String!, $weights: Weights!) {\n    autofillResources(customerName: $customerName, targetCustomerName: $targetCustomerName, weights: $weights)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AutofillFields(\n    $customerName: String!\n    $targetCustomerName: String!\n    $fieldWeights: Weights!\n    $elementWeights: Weights!\n    $resoResource: String\n  ) {\n    autofillFields(\n      customerName: $customerName\n      targetCustomerName: $targetCustomerName\n      fieldWeights: $fieldWeights\n      elementWeights: $elementWeights\n      resoResource: $resoResource\n    )\n  }\n"): (typeof documents)["\n  mutation AutofillFields(\n    $customerName: String!\n    $targetCustomerName: String!\n    $fieldWeights: Weights!\n    $elementWeights: Weights!\n    $resoResource: String\n  ) {\n    autofillFields(\n      customerName: $customerName\n      targetCustomerName: $targetCustomerName\n      fieldWeights: $fieldWeights\n      elementWeights: $elementWeights\n      resoResource: $resoResource\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AutofillElements(\n    $customerName: String!\n    $targetCustomerName: String!\n    $weights: Weights!\n    $resoResource: String\n    $resoField: String\n  ) {\n    autofillElements(\n      customerName: $customerName\n      targetCustomerName: $targetCustomerName\n      weights: $weights\n      resoResource: $resoResource\n      resoField: $resoField\n    )\n  }\n"): (typeof documents)["\n  mutation AutofillElements(\n    $customerName: String!\n    $targetCustomerName: String!\n    $weights: Weights!\n    $resoResource: String\n    $resoField: String\n  ) {\n    autofillElements(\n      customerName: $customerName\n      targetCustomerName: $targetCustomerName\n      weights: $weights\n      resoResource: $resoResource\n      resoField: $resoField\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MappingResource on Resource {\n    # id\n    name\n    # description\n    # createDate\n    # modifyDate\n  }\n"): (typeof documents)["\n  fragment MappingResource on Resource {\n    # id\n    name\n    # description\n    # createDate\n    # modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MappingField on Field {\n    # id\n    resourceName\n    standardName\n    displayName\n    # displayNameFrench\n    # displayNameSpanish\n    definition\n    simpleDataType\n    # maxLength\n    # suggestedPrecision\n    # recordId\n    # lookupStatus\n    lookup\n    # propertyTypes\n    # collection\n    # edmDataType\n    resoDefined\n    # physical\n    # queryable\n    # validationType\n    # createBy\n    # modifyBy\n    # createDate\n    # modifyDate\n  }\n"): (typeof documents)["\n  fragment MappingField on Field {\n    # id\n    resourceName\n    standardName\n    displayName\n    # displayNameFrench\n    # displayNameSpanish\n    definition\n    simpleDataType\n    # maxLength\n    # suggestedPrecision\n    # recordId\n    # lookupStatus\n    lookup\n    # propertyTypes\n    # collection\n    # edmDataType\n    resoDefined\n    # physical\n    # queryable\n    # validationType\n    # createBy\n    # modifyBy\n    # createDate\n    # modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MappingLookup on Lookup {\n    # id\n    lookupField\n    lookupValue\n    lookupDisplayName\n    # lookupDisplayNameFrench\n    # lookupDisplayNameSpanish\n    definition\n    # lookupDisplayNameSynonyms\n    # references\n    # elementStatus\n    # lookupId\n    # lookupFieldId\n    resoDefined\n    # createBy\n    # modifyBy\n    # createDate\n    # modifyDate\n  }\n"): (typeof documents)["\n  fragment MappingLookup on Lookup {\n    # id\n    lookupField\n    lookupValue\n    lookupDisplayName\n    # lookupDisplayNameFrench\n    # lookupDisplayNameSpanish\n    definition\n    # lookupDisplayNameSynonyms\n    # references\n    # elementStatus\n    # lookupId\n    # lookupFieldId\n    resoDefined\n    # createBy\n    # modifyBy\n    # createDate\n    # modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MappingCustomerField on CustomerField {\n    # id\n    customerName\n    resourceName\n    standardName\n    displayName\n    # displayNameFrench\n    # displayNameSpanish\n    definition\n    # deprecated\n    # majorChange\n    # cloneField\n    # inputType\n    # createBy\n    # modifyBy\n    # createDate\n    # modifyDate\n  }\n"): (typeof documents)["\n  fragment MappingCustomerField on CustomerField {\n    # id\n    customerName\n    resourceName\n    standardName\n    displayName\n    # displayNameFrench\n    # displayNameSpanish\n    definition\n    # deprecated\n    # majorChange\n    # cloneField\n    # inputType\n    # createBy\n    # modifyBy\n    # createDate\n    # modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MappingCustomerLookup on CustomerLookup {\n    # id\n    customerName\n    lookupField\n    lookupValue\n    lookupDisplayName\n    # lookupDisplayNameFrench\n    # lookupDisplayNameSpanish\n    # ordinal\n    # lookupStatus\n    # lookupFieldConstraint1\n    # lookupValue1\n    # lookupFieldConstraint2\n    # lookupValue2\n    # createBy\n    # modifyBy\n    # createDate\n    # modifyDate\n  }\n"): (typeof documents)["\n  fragment MappingCustomerLookup on CustomerLookup {\n    # id\n    customerName\n    lookupField\n    lookupValue\n    lookupDisplayName\n    # lookupDisplayNameFrench\n    # lookupDisplayNameSpanish\n    # ordinal\n    # lookupStatus\n    # lookupFieldConstraint1\n    # lookupValue1\n    # lookupFieldConstraint2\n    # lookupValue2\n    # createBy\n    # modifyBy\n    # createDate\n    # modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AmpMetadata on AmpMetadata {\n    customers\n    resources {\n      ...MappingResource\n    }\n    fields {\n      ...MappingField\n    }\n    lookups {\n      ...MappingLookup\n    }\n    customerFields {\n      ...MappingCustomerField\n    }\n    customerLookups {\n      ...MappingCustomerLookup\n    }\n  }\n"): (typeof documents)["\n  fragment AmpMetadata on AmpMetadata {\n    customers\n    resources {\n      ...MappingResource\n    }\n    fields {\n      ...MappingField\n    }\n    lookups {\n      ...MappingLookup\n    }\n    customerFields {\n      ...MappingCustomerField\n    }\n    customerLookups {\n      ...MappingCustomerLookup\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAmpMetadata($customerName: String!) {\n    ampMetadata(customerName: $customerName) {\n      ...AmpMetadata\n    }\n  }\n"): (typeof documents)["\n  query GetAmpMetadata($customerName: String!) {\n    ampMetadata(customerName: $customerName) {\n      ...AmpMetadata\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ReportStatistics on ReportStatistics {\n    count\n    unique\n    filled\n    names\n  }\n"): (typeof documents)["\n  fragment ReportStatistics on ReportStatistics {\n    count\n    unique\n    filled\n    names\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ReportInfo on ReportInfo {\n    total\n    unique\n    filled\n    unmapped {\n      ...ReportStatistics\n    }\n    verify {\n      ...ReportStatistics\n    }\n    review {\n      ...ReportStatistics\n    }\n    mapped {\n      ...ReportStatistics\n    }\n    deleted {\n      ...ReportStatistics\n    }\n  }\n"): (typeof documents)["\n  fragment ReportInfo on ReportInfo {\n    total\n    unique\n    filled\n    unmapped {\n      ...ReportStatistics\n    }\n    verify {\n      ...ReportStatistics\n    }\n    review {\n      ...ReportStatistics\n    }\n    mapped {\n      ...ReportStatistics\n    }\n    deleted {\n      ...ReportStatistics\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MappingReport on MappingReport {\n    resourceInfo {\n      ...ReportInfo\n    }\n    fieldInfo {\n      ...ReportInfo\n    }\n  }\n"): (typeof documents)["\n  fragment MappingReport on MappingReport {\n    resourceInfo {\n      ...ReportInfo\n    }\n    fieldInfo {\n      ...ReportInfo\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMappingReport($customerName: String!, $targetCustomerName: String!) {\n    mappingReport(customerName: $customerName, targetCustomerName: $targetCustomerName) {\n      ...MappingReport\n    }\n  }\n"): (typeof documents)["\n  query GetMappingReport($customerName: String!, $targetCustomerName: String!) {\n    mappingReport(customerName: $customerName, targetCustomerName: $targetCustomerName) {\n      ...MappingReport\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Requirement on Requirement {\n    id\n    resourceName\n    targetCustomerName\n    subjectName\n    requirementStatus\n    type\n    responseMessage\n    createDate\n    modifyDate\n  }\n"): (typeof documents)["\n  fragment Requirement on Requirement {\n    id\n    resourceName\n    targetCustomerName\n    subjectName\n    requirementStatus\n    type\n    responseMessage\n    createDate\n    modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSeedRequirements($resourceName: String!) {\n    seedRequirements(resourceName: $resourceName) {\n      ...Requirement\n    }\n  }\n"): (typeof documents)["\n  query GetSeedRequirements($resourceName: String!) {\n    seedRequirements(resourceName: $resourceName) {\n      ...Requirement\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomerRequirements($resourceName: String!, $targetCustomerName: String!) {\n    customerRequirements(resourceName: $resourceName, targetCustomerName: $targetCustomerName) {\n      ...Requirement\n    }\n  }\n"): (typeof documents)["\n  query GetCustomerRequirements($resourceName: String!, $targetCustomerName: String!) {\n    customerRequirements(resourceName: $resourceName, targetCustomerName: $targetCustomerName) {\n      ...Requirement\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveRequirements($inputs: [RequirementInput!]!) {\n    saveRequirements(inputs: $inputs) {\n      requirements {\n        ...Requirement\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveRequirements($inputs: [RequirementInput!]!) {\n    saveRequirements(inputs: $inputs) {\n      requirements {\n        ...Requirement\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteRequirements($ids: [ID!]!) {\n    deleteRequirements(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteRequirements($ids: [ID!]!) {\n    deleteRequirements(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Resource on Resource {\n    id\n    name\n    description\n    createDate\n    modifyDate\n  }\n"): (typeof documents)["\n  fragment Resource on Resource {\n    id\n    name\n    description\n    createDate\n    modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetResources {\n    resources {\n      ...Resource\n    }\n  }\n"): (typeof documents)["\n  query GetResources {\n    resources {\n      ...Resource\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveResources($inputs: [ResourceInput!]!) {\n    saveResources(inputs: $inputs) {\n      resources {\n        ...Resource\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveResources($inputs: [ResourceInput!]!) {\n    saveResources(inputs: $inputs) {\n      resources {\n        ...Resource\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteResources($ids: [ID!]!) {\n    deleteResources(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteResources($ids: [ID!]!) {\n    deleteResources(ids: $ids) {\n      ids {\n        id\n        deleted\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RetsData on RetsData {\n    id\n    sourceCustomerName\n    sourceResourceName\n    sourceClassName\n    sourceBoardName\n    sourceUniqueKey\n    sourceListingKey\n    sourceMlsStatus\n    destinationCustomerName\n    destinationListingKeyAssigned\n    destinationDuplicatesListingKey\n  }\n"): (typeof documents)["\n  fragment RetsData on RetsData {\n    id\n    sourceCustomerName\n    sourceResourceName\n    sourceClassName\n    sourceBoardName\n    sourceUniqueKey\n    sourceListingKey\n    sourceMlsStatus\n    destinationCustomerName\n    destinationListingKeyAssigned\n    destinationDuplicatesListingKey\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRetsDataPage(\n    $customerName: String\n    $search: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n    $filters: [FilterInput]\n  ) {\n    retsDataPage(\n      customerName: $customerName\n      search: $search\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n      filters: $filters\n    ) {\n      id\n      page {\n        ...RetsData\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n"): (typeof documents)["\n  query GetRetsDataPage(\n    $customerName: String\n    $search: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n    $filters: [FilterInput]\n  ) {\n    retsDataPage(\n      customerName: $customerName\n      search: $search\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n      filters: $filters\n    ) {\n      id\n      page {\n        ...RetsData\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRetsDataCustomers {\n    retsDataCustomers\n  }\n"): (typeof documents)["\n  query GetRetsDataCustomers {\n    retsDataCustomers\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RetsField on RetsField {\n    id\n    customerName\n    resourceName\n    resourceVisibleName\n    resourceDescription\n    keyField\n    classVersion\n    classDate\n    lookupVersion\n    lookupDate\n    className\n    classVisibleName\n    classDescription\n    tableVersion\n    tableDate\n    metadataEntryId\n    systemName\n    standardName\n    longName\n    dbName\n    shortName\n    maximumLength\n    dataType\n    fieldPrecision\n    searchable\n    interpretation\n    lookupName\n    maxSelect\n    units\n    inIndex\n    minimum\n    maximum\n    required\n    uniqueIdentifier\n    inKeyIndex\n  }\n"): (typeof documents)["\n  fragment RetsField on RetsField {\n    id\n    customerName\n    resourceName\n    resourceVisibleName\n    resourceDescription\n    keyField\n    classVersion\n    classDate\n    lookupVersion\n    lookupDate\n    className\n    classVisibleName\n    classDescription\n    tableVersion\n    tableDate\n    metadataEntryId\n    systemName\n    standardName\n    longName\n    dbName\n    shortName\n    maximumLength\n    dataType\n    fieldPrecision\n    searchable\n    interpretation\n    lookupName\n    maxSelect\n    units\n    inIndex\n    minimum\n    maximum\n    required\n    uniqueIdentifier\n    inKeyIndex\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRetsFieldsPage(\n    $customerName: String\n    $resource: String\n    $field: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n  ) {\n    retsFieldsPage(\n      customerName: $customerName\n      resource: $resource\n      field: $field\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n    ) {\n      id\n      page {\n        ...RetsField\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n"): (typeof documents)["\n  query GetRetsFieldsPage(\n    $customerName: String\n    $resource: String\n    $field: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n  ) {\n    retsFieldsPage(\n      customerName: $customerName\n      resource: $resource\n      field: $field\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n    ) {\n      id\n      page {\n        ...RetsField\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRetsField(\n    $customerName: String!,\n    $resourceName: String!,\n    $className: String,\n    $systemName: String!\n  ) {\n    retsField(\n      customerName: $customerName\n      resourceName: $resourceName\n      className: $className\n      systemName: $systemName\n    ) {\n      ...RetsField\n    }\n  }\n"): (typeof documents)["\n  query GetRetsField(\n    $customerName: String!,\n    $resourceName: String!,\n    $className: String,\n    $systemName: String!\n  ) {\n    retsField(\n      customerName: $customerName\n      resourceName: $resourceName\n      className: $className\n      systemName: $systemName\n    ) {\n      ...RetsField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRetsFieldsCustomers {\n    retsFieldsCustomers\n  }\n"): (typeof documents)["\n  query GetRetsFieldsCustomers {\n    retsFieldsCustomers\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RetsLookup on RetsLookup {\n    id\n    customerName\n    metadataResourceName\n    metadataVersion\n    metadataDate\n    lookupMetadataEntryId\n    lookupName\n    visibleName\n    lookupTypeVersion\n    lookupTypeDate\n    metadataEntryId\n    longValue\n    shortValue\n    value\n  }\n"): (typeof documents)["\n  fragment RetsLookup on RetsLookup {\n    id\n    customerName\n    metadataResourceName\n    metadataVersion\n    metadataDate\n    lookupMetadataEntryId\n    lookupName\n    visibleName\n    lookupTypeVersion\n    lookupTypeDate\n    metadataEntryId\n    longValue\n    shortValue\n    value\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRetsLookupsPage(\n    $customerName: String\n    $resource: String\n    $field: String\n    $value: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n  ) {\n    retsLookupsPage(\n      customerName: $customerName\n      resource: $resource\n      field: $field\n      value: $value\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n    ) {\n      id\n      page {\n        ...RetsLookup\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n"): (typeof documents)["\n  query GetRetsLookupsPage(\n    $customerName: String\n    $resource: String\n    $field: String\n    $value: String\n    $page: Int\n    $size: Int\n    $sort: String\n    $direction: SortDirection\n  ) {\n    retsLookupsPage(\n      customerName: $customerName\n      resource: $resource\n      field: $field\n      value: $value\n      page: $page\n      size: $size\n      sort: $sort\n      direction: $direction\n    ) {\n      id\n      page {\n        ...RetsLookup\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRetsFieldLookups(\n    $customerName: String\n    $resourceName: String!\n    $lookupName: String!\n  ) {\n    retsFieldLookups(\n      customerName: $customerName\n      resourceName: $resourceName\n      lookupName: $lookupName\n    ) {\n      ...RetsLookup\n    }\n  }\n"): (typeof documents)["\n  query GetRetsFieldLookups(\n    $customerName: String\n    $resourceName: String!\n    $lookupName: String!\n  ) {\n    retsFieldLookups(\n      customerName: $customerName\n      resourceName: $resourceName\n      lookupName: $lookupName\n    ) {\n      ...RetsLookup\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRetsLookupsCustomers {\n    retsLookupsCustomers\n  }\n"): (typeof documents)["\n  query GetRetsLookupsCustomers {\n    retsLookupsCustomers\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ClearServerCache {\n    clearServerCache\n  }\n"): (typeof documents)["\n  mutation ClearServerCache {\n    clearServerCache\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation WipeCustomerMetadata($customerName: String!) {\n    wipeCustomerMetadata(customerName: $customerName)\n  }\n"): (typeof documents)["\n  mutation WipeCustomerMetadata($customerName: String!) {\n    wipeCustomerMetadata(customerName: $customerName)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeduplicateLookups {\n    deduplicateLookups\n  }\n"): (typeof documents)["\n  mutation DeduplicateLookups {\n    deduplicateLookups\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment User on User {\n    id\n    fullName\n    email\n    roles {\n      userId\n      roleName\n    }\n    groups {\n      userId\n      groupId\n    }\n    createDate\n    modifyDate\n  }\n"): (typeof documents)["\n  fragment User on User {\n    id\n    fullName\n    email\n    roles {\n      userId\n      roleName\n    }\n    groups {\n      userId\n      groupId\n    }\n    createDate\n    modifyDate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUser($id: ID!) {\n    user(id: $id) {\n      ...User\n    }\n  }\n"): (typeof documents)["\n  query GetUser($id: ID!) {\n    user(id: $id) {\n      ...User\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUsers {\n    users {\n      ...User\n    }\n  }\n"): (typeof documents)["\n  query GetUsers {\n    users {\n      ...User\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveUser($input: UserInput!) {\n    saveUser(input: $input) {\n      user {\n        ...User\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveUser($input: UserInput!) {\n    saveUser(input: $input) {\n      user {\n        ...User\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteUser($id: ID!) {\n    deleteUser(id: $id) {\n      id\n      deleted\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteUser($id: ID!) {\n    deleteUser(id: $id) {\n      id\n      deleted\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveGroups($id: ID!, $groups: [String!]) {\n    saveGroups(id: $id, groups: $groups) {\n      user {\n        ...User\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveGroups($id: ID!, $groups: [String!]) {\n    saveGroups(id: $id, groups: $groups) {\n      user {\n        ...User\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveRoles($id: ID!, $roles: [String!]) {\n    saveRoles(id: $id, roles: $roles) {\n      user {\n        ...User\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveRoles($id: ID!, $roles: [String!]) {\n    saveRoles(id: $id, roles: $roles) {\n      user {\n        ...User\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;